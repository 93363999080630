
import React from "react";
import { Box, Container, Grid, Typography, Input, Button, Divider } from "@mui/material";
import SavingEveryday from "../assets/img/savingEveryday.png";
import SpenseDream from "../assets/img/spense_dream_img.png";
import AutoSaves from "../assets/img/autoSaves.png";
import SaveTogether from "../assets/img/saveTogether.png";
import { Parallax } from 'react-scroll-parallax';
import { ParallaxProvider } from "react-scroll-parallax";

import SavingGoal from "../assets/img/img_saving_goal.png";
import InviteFriends from "../assets/img/img_invite_friends.png";
import SaveRules from "../assets/img/img_save_rules.png";

import SavingRewarding from "../assets/img/img_savings.png";


const Features = () => {
  return (
    <div>
      {/* Spense your dreams in 3 steps */}
      <Grid sx={{ mt: { xs: '80px', lg: '160px' }, backgroundColor: '#F6F3FF' }}>
        <Container>
          <Grid container display={'flex'} sx={{ p: { xs: '54px 0 54px 0', lg: '72px 0 72px 0' } }}>
            <Grid lg={5} sx={{ alignSelf: 'center', display: { xs: "none", lg: "block" } }}>
              <Parallax translateY={[30, -30]}>
                <img src={SpenseDream} style={{ width: '100%' }} />
              </Parallax>
            </Grid>
            <Grid lg={7} sx={{ alignSelf: 'center', pl: { lg: 14 }, mb: { xs: 2, lg: 0 } }}>
              <Parallax translateY={[30, -30]}>
                <Typography variant="h3" style={{ background: "-webkit-linear-gradient(0deg, #4C00D2 1%, #FF1C7B 98.31%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} >
                  Spense your dreams in 3 simple steps
                </Typography>
                <Box sx={{ display: 'flex', mt: { xs: '20px', lg: '34px' } }}>
                  <Parallax translateY={[30, -30]}>
                    <img src={SavingGoal} style={{ width: '90%' }} />
                  </Parallax>
                  <Parallax translateY={[30, -30]}>
                    <img src={InviteFriends} style={{ width: '90%' }} />
                  </Parallax>
                  <Parallax translateY={[30, -30]}>
                    <img src={SaveRules} style={{ width: '90%' }} />
                  </Parallax>
                </Box>
                <Typography variant="h5" style={{ color: '#111' }}>
                  and you are done, it is that simple.
                </Typography>
              </Parallax>
            </Grid>
            {/* <Grid sx={{ display: { xs: "flex", lg: "none" }}}>
              <Parallax translateY={[30, -30]} style={{display:'flex', justifyContent:'center'}}>
                <img src={SpenseDream} style={{ width: '70%'}} />
              </Parallax>
            </Grid> */}
          </Grid>
        </Container>
      </Grid>
      {/* Spense your dreams in 3 steps */}

      {/* Saving is an everyday thing! Starts */}
      <Grid sx={{ backgroundColor: '#F9F9F9' }}>
        <Container>
          <Grid container sx={{ p: { xs: '54px 0 54px 0', lg: '72px 0 72px 0' } }}>
            <Grid lg={6} sx={{ alignSelf: 'center', pr: { lg: 14 } }}>
              <Parallax translateY={[30, -30]}>
                <Typography variant="h3" style={{ background: "-webkit-linear-gradient(0deg, #4C00D2 1%, #FF1C7B 98.31%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} >
                  Save today, and every day
                </Typography>
                <Typography variant="body1" sx={{ mt: { xs: '20px', lg: '24px' }, mb: { xs: 2, lg: 0 } }} style={{ color: '#111' }}>
                  Most of us think of housing or retirement when it comes to saving. It's just as much about the smaller things in everyday life.
                </Typography>
              </Parallax>
            </Grid>
            <Grid lg={6} sx={{ alignSelf: 'center' }}>
              <Parallax translateY={[30, -30]}>
                <img src={SavingEveryday} style={{ width: '100%' }} />
              </Parallax>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      {/* Saving is an everyday thing! Ends */}

      {/* Saving is an everyday thing! Ends */}
      <Grid sx={{ backgroundColor: '#F6F3FF' }}>
        <Container>
          <Grid container sx={{ p: { xs: '54px 0 54px 0', lg: '72px 0 72px 0' } }}>
            <Grid lg={6} sx={{ alignSelf: 'center', display: { xs: "none", lg: "block" } }}>
              <Parallax translateY={[30, -30]}>
                <img src={SaveTogether} style={{ width: '100%' }} />
              </Parallax>
            </Grid>
            <Grid lg={6} sx={{ alignSelf: 'center', pl: { lg: 14 }, mb: { xs: 2, lg: 0 } }}>
              <Parallax translateY={[30, -30]}>
                <Typography variant="h3" style={{ background: "-webkit-linear-gradient(0deg, #4C00D2 1%, #FF1C7B 98.31%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} >
                  Save together or go solo
                </Typography>
                <Typography variant="body1" sx={{ mt: { xs: '20px', lg: '24px' } }} style={{ color: '#111' }}>
                  Certain things in life are difficult to achieve alone. Fortunately, it's easy to save up for common goals when using Spense. You can invite friends and family, track each other’s progress, and celebrate goal achievements.
                </Typography>
              </Parallax>
            </Grid>
            <Grid lg={6} sx={{ alignSelf: 'center', display: { xs: "block", lg: "none" } }}>
              <Parallax translateY={[30, -30]}>
                <img src={SaveTogether} style={{ width: '100%' }} />
              </Parallax>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      {/* Saving is an everyday thing! Ends */}

      {/* Auto Saves Starts */}
      <Grid sx={{ backgroundColor: '#F9F9F9' }}>
        <Container>
          <Grid container sx={{ p: { xs: '54px 0 54px 0', lg: '72px 0 72px 0' } }}>
            <Grid lg={6} sx={{ alignSelf: 'center', pr: { lg: 14 } }}>
              <Parallax translateY={[30, -30]}>
                <Typography variant="h3" style={{ background: "-webkit-linear-gradient(0deg, #4C00D2 1%, #FF1C7B 98.31%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} >
                  Saving is now automated
                </Typography>
                <Typography variant="body1" sx={{ mt: { xs: '20px', lg: '24px' }, mb: { xs: 2, lg: 0 } }} style={{ color: '#111' }}>
                  Take advantage of Spense’s fun, easy to use saving rules. It will help deposit money for your goals automatically. Every time you make a transaction like taking an Uber or ordering online, we deposit some amount and help you save more.
                </Typography>
              </Parallax>
            </Grid>
            <Grid lg={6} sx={{ alignSelf: 'center' }}>
              <Parallax translateY={[30, -30]}>
                <img src={AutoSaves} style={{ width: '100%' }} />
              </Parallax>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      {/* Auto Saves Starts */}

      {/* Saving is rewarding */}
      <Grid sx={{ backgroundColor: '#F6F3FF' }}>
        <Container>
          <Grid container sx={{ p: { xs: '54px 0 54px 0', lg: '72px 0 72px 0' } }}>
            <Grid lg={6} sx={{ alignSelf: 'center', display: { xs: "none", lg: "block" } }}>
              <Parallax translateY={[30, -30]}>
                <img src={SavingRewarding} style={{ width: '100%' }} />
              </Parallax>
            </Grid>
            <Grid lg={6} sx={{ alignSelf: 'center', pl: { lg: 14 }, mb: { xs: 2, lg: 0 } }}>
              <Parallax translateY={[30, -30]}>
                <Typography variant="h3" style={{ background: "-webkit-linear-gradient(0deg, #4C00D2 1%, #FF1C7B 98.31%)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }} >
                  Saving is rewarding
                </Typography>
                <Typography variant="body1" sx={{ mt: { xs: '20px', lg: '24px' } }} style={{ color: '#111' }}>
                  You always win with Spense. <br/>Every milestone earns you Spense coins. These can be redeemed for cash rewards, gifts, coupons, and purchases directly on Spense.
                </Typography>
              </Parallax>
            </Grid>
            <Grid lg={6} sx={{ alignSelf: 'center', display: { xs: "block", lg: "none" } }}>
              <Parallax translateY={[30, -30]}>
                <img src={SavingRewarding} style={{ width: '100%' }} />
              </Parallax>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      {/* Saving is rewarding */}

    </div>
  );
};
export default Features;
