import "./App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import { Fragment } from "react";

const THEME = createTheme({

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1620,
      xxxl: 1920
    }
  }

});

THEME.typography.h1 = {
  [THEME.breakpoints.up("xs")]: {
    fontSize: "3.2rem",
    fontWeight: 700,
    color:"#fff",
    letterSpacing:'-2px',
    lineHeight:'64px',
    textTransform:"none"
  },
  [THEME.breakpoints.up("lg")]: {
    fontSize: "5.5rem",
    lineHeight:'96px'
  },
};

THEME.typography.h2 = {
  [THEME.breakpoints.up("xs")]: {
    fontSize: "3rem"
  },
  [THEME.breakpoints.up("lg")]: {
    fontSize: "3rem"
  }
};


THEME.typography.h3 = {
  [THEME.breakpoints.up("xs")]: {
    fontSize: "2.5rem",
    fontWeight: 700,
    color:"#fff",
    letterSpacing:'-3px',
    lineHeight:'48px',
    textTransform:"none"
  },
  [THEME.breakpoints.up("lg")]: {
    fontSize: "3.5rem",
    lineHeight:'64px',
  }
};

THEME.typography.h4 = {
  [THEME.breakpoints.up("xs")]: {
    fontSize: "1.5rem",
    fontWeight: 600,
    color:"#fff",
    letterSpacing:'-1px',
    textTransform:"none"
  },
  [THEME.breakpoints.up("lg")]: {
    fontSize: "2.5rem"
  }
};

THEME.typography.h5 = {
  [THEME.breakpoints.up("xs")]: {
    fontSize: "1.25rem",
    fontWeight: 600,
    color:"#fff",
    letterSpacing:'-1px',
    textTransform:"none"
  },
  [THEME.breakpoints.up("lg")]: {
    fontSize: "1.5rem"
  }
};

THEME.typography.body1 = {
  [THEME.breakpoints.up("xs")]: {
    fontSize: "1.25rem",
    fontWeight: 400,
    color:"#ffffff",
    letterSpacing:'-1px',
    textTransform:"none"
  },
  [THEME.breakpoints.up("lg")]: {
    fontSize: "1.5rem",
    letterSpacing:'-1px'
  }
};

THEME.typography.body2 = {
  [THEME.breakpoints.up("xs")]: {
    fontSize: "1rem",
    fontWeight: 400,
    color:"#fff",
    letterSpacing:'0px',
    textTransform:"none"
  },
  [THEME.breakpoints.up("lg")]: {
    fontSize: "1.25rem",
    letterSpacing:'-.25px'
  }
};

THEME.typography.subtitle1 = {
  [THEME.breakpoints.up("xs")]: {
    fontSize: ".85rem",
    fontWeight: 400,
    color:"#fff",
    textTransform:"none"
  },
  [THEME.breakpoints.up("lg")]: {
    fontSize: "1rem"
  }
};

THEME.typography.subtitle2 = {
  [THEME.breakpoints.up("xs")]: {
    fontSize: ".85rem",
    fontWeight: 400,
    color:"#fff",
    textTransform:"none"
  },
  [THEME.breakpoints.up("lg")]: {
    fontSize: ".85rem"
  }
};


function App() {
  return (
    <div>
      <ThemeProvider theme={THEME}>
        <Router>
          <Fragment>
            <Routes>
              <Route exact path="/" element={<Home />} />
            </Routes>
          </Fragment>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
