
import React from "react";
import { Box, Container, Grid, Typography, Input, Button, Divider } from "@mui/material";
import YesBank from "../assets/img/img_yesbank.png";
import Microsoft from "../assets/img/img_microsoft.png";
import Paytm from "../assets/img/img_paytm.png";
import Razorpay from "../assets/img/img_razorpay.png";
import NPCI from "../assets/img/img_npci.png";
import PhonePe from "../assets/img/img_phonepe.png";
import IDBI from "../assets/img/img_idbi.png";
import PineLabs from "../assets/img/img_pinelabs.png";


import Wing from "../assets/img/whoWeAreWing.png";
import { Parallax } from 'react-scroll-parallax';

const OurPartners = () => {
  return (
    <div>
      <Grid sx={{ backgroundColor: '#f3f3f3', p: { xs: '54px 0 54px 0', lg: '72px 0 72px 0' } }}>
        <Parallax translateY={[10, -5]}>
          <Container>
            <Grid container>
              <Typography width='100%' variant='h3' sx={{ mb: { xs: '32px', lg: '0px' } }} style={{ color: '#111' }}>Our Partners</Typography>
              <Box sx={{ display: 'flex', mt: { xs: '20px', lg: '34px' }, mb: { xs: '0px', lg: '-50px' }, width: '100%', justifyContent: 'space-between' }}>
                <img src={YesBank} style={{ maxWidth: '25%' }} />
                <img src={Microsoft} style={{ maxWidth: '25%' }} />
                <img src={Paytm} style={{ maxWidth: '25%' }} />
                <img src={Razorpay} style={{ maxWidth: '25%' }} />
              </Box>
              <Box sx={{ display: 'flex', mt: { xs: '0px', lg: '20px' }, width: '100%', justifyContent: 'space-between' }}>
                <img src={NPCI} style={{ maxWidth: '25%' }} />
                <img src={PhonePe} style={{ maxWidth: '25%' }} />
                <img src={IDBI} style={{ maxWidth: '25%' }} />
                <img src={PineLabs} style={{ maxWidth: '25%' }} />
              </Box>
            </Grid>
          </Container>
        </Parallax>
      </Grid>

    </div>
  );
};
export default OurPartners;