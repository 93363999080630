import React, { useRef, useState } from "react";
import { Box, Container, Grid, Typography, Input, Button, Stack, Snackbar } from "@mui/material";
import Mobile1 from "../assets/img/mobile1.png";
import Mobile2 from "../assets/img/mobile2.png";
import HeroUSP from "./heroUSP";

import { Parallax } from 'react-scroll-parallax';
import { ParallaxProvider } from "react-scroll-parallax";
import HeroWing from "../assets/img/heroWings.png";
import "../App.css";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

const HeroBanner = () => {

  const [mobNumber, setMobNumber] = useState({ value: '' });
  const [open, setOpen] = React.useState(false);
  const [dialogMessage, setDialogMessage] = useState('');

  const clearMobile = () => {
    setMobNumber({ value: '' });
    document.getElementById('mobileNumber').value = '';
  };

  const showDialog = () => { setOpen(true); };
  const hideDialog = () => { setOpen(false); clearMobile(true); };

  const submitMobNumber = async () => {
    if (!isNaN(mobNumber.value) && String(mobNumber.value).length === 10) {
      const data = { user_mobile: mobNumber.value };
      //Alpha link
      fetch("https://dmpw583kz3.execute-api.us-east-1.amazonaws.com/alpha/add_user", {
      //Live link
      // fetch("https://b98mrkqc6l.execute-api.ap-south-1.amazonaws.com/live/add_user", {
        method: "POST",
        body: JSON.stringify(data),
      })
        .then(r => r.json())
        .then(data => {
          showDialog();
          setDialogMessage(data.Message)
          console.log(data)
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      showDialog();
      setDialogMessage('Please Enter Valid Mobile Number')
    }

  };
  return (
    <ParallaxProvider>
      <Dialog open={open} onClose={hideDialog}>
        <Box style={{ flexDirection: 'row' }}>
          <Box><DialogTitle id="alert-dialog-title">{dialogMessage}</DialogTitle></Box>
          <Box><DialogActions><Button onClick={hideDialog} autoFocus>Ok</Button></DialogActions></Box>
        </Box>
      </Dialog>
      <Grid className="Hero-wing" >
        <Parallax speed={-200}>
          <Box>
            <Box sx={{ width: '100%', height: '1200px', backgroundImage: 'linear-gradient(rgba(54,0,148,0), #360094)', position: 'absolute', bottom: 0 }}></Box>
            <img src={HeroWing} />
          </Box>
        </Parallax>
      </Grid>
      <Container sx={{ zIndex: 1000 }}>
        <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Grid lg={6}>
            <Typography variant="h1" sx={{ mt: { xs: "40px", lg: "120px" }, width: { xs: '100%', lg: '110%' } }}>
              Save smarter. Save together.
            </Typography>
            <Typography variant="body1" sx={{ mt: { xs: "12px", lg: "32px" }, opacity: "0.7" }}>
              India's first social savings app. We make saving money simple, social, and fun.
            </Typography>
            <Box sx={{ display: "flex", mt: { xs: "48px", lg: "72px" }, height: { xs: "60px", lg: "80px" } }}>
              <Box sx={{ display: "flex", borderRadius: 50, backgroundColor: "#ffff", width: '98%' }}>
                <Typography variant="body2" style={{ color: "#111" }} sx={{ ml: '20px', mt: { xs: '20px', lg: '28px' } }}> +91 </Typography>
                <Input onChange={data => { setMobNumber({ value: data.target.value }) }} inputProps={{ type: 'tel', maxlength: '10', pattern: '[0-9]' }} id="mobileNumber" disableUnderline={true} placeholder="Mobile Number" style={{ color: "#111111", backgroundColor: "white", width: "100%", marginLeft: "10px" }} />
                <Button onClick={submitMobNumber} sx={{ borderTopRightRadius: 50, borderBottomRightRadius: 50, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, width: '280px' }} variant="contained" >
                  <Typography variant="body2">Join waitlist </Typography>
                </Button>
              </Box>
            </Box>
            <Typography variant="body2" sx={{ mt: "16px", opacity: "0.7" }}  >Be the first to experience Spense</Typography>
          </Grid>
          <Grid lg={6} sx={{ display: 'flex', flexDirection: 'row', justifyContent: { xs: 'center', lg:'flex-end' }, width: '100%', mt: {xs:'20px',lg:'20px'}, zIndex: 1000 }}>
            <Parallax speed={5}><Box sx={{ mt: { xs: '0px', lg: '20px' }, width: { xs: "230px", lg: '300px' } }} ><img src={Mobile2} width='100%' /></Box></Parallax>
            <Parallax speed={10}><Box sx={{ mt: { xs: '20px', lg: '80px' }, ml: { xs: '-160px' }, width: { xs: "280px", lg: '320px' } }} ><img src={Mobile1} width='100%' /></Box></Parallax>
          </Grid>
          <HeroUSP />
        </Grid>
      </Container>
    </ParallaxProvider >
  );
};

export default HeroBanner;
