
import React from "react";
import { Box, Container, Grid, Typography, Input, Button, Divider } from "@mui/material";
import HIW00 from "../assets/img/hiw00.png";
import HIW01 from "../assets/img/hiw01.png";
import HIW02 from "../assets/img/hiw02.png";
import { Parallax } from "react-scroll-parallax";


const HowItWorks = () => {
  return (
    <div>
      {/* Saving is an everyday thing! Starts */}
      <Grid sx={{ backgroundColor: '#4D2255', p: { xs: '54px 0 54px 0', lg: '120px 0 120px 0' } }} >
        <Container>
          <Parallax translateY={[10, -5]} style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Grid container xs={12} sx={{ display: 'flex', justifyContent: { xs: 'center', lg: 'space-between' }, flexWrap: 'nowrap' }} >
              <Box>
                <Typography variant="h4" sx={{ textAlign: { xs: 'center', lg: 'left' } }}>
                  Spense is safe as a bank
                </Typography>
                <Typography variant="body1" sx={{ mt: { xs: '12px', lg: '12px' }, mb: { xs: 8, lg: 4 }, opacity: '.7', textAlign: { xs: 'center', lg: 'left' } }} >
                  Spense works with our partner banks. This means, that your savings are safe.
                </Typography>
              </Box>
              {/* <Box sx={{ height: 160, mr: 4 }}><img src={HIW00} style={{ height: 140 }} /></Box> */}
            </Grid>
          </Parallax>
          <Divider sx={{ bgcolor: "white", mt: { xs: 0, lg: 4 }, opacity: '0.4' }} />
          <Parallax translateY={[10, -5]}>
            <Grid columnGap={8} sx={{ display: { xs: 'block', lg: 'flex' }, mt: { xs: 0, lg: 4 } }}>
              <Grid container lg={4} sx={{ justifyContent: { xs: 'center', lg: 'left' }, display: 'flex', alignItems: { xs: 'center', lg: 'flex-start' }, flexDirection: 'column' }}>
                <Box sx={{ height: 160 }}><img src={HIW02} style={{ height: 140 }} /></Box>
                <Typography variant="h4" sx={{ textAlign: { xs: 'center', lg: 'left' } }}>
                  Spense works no matter what bank you have
                </Typography>
                <Typography variant="body1" sx={{ mt: { xs: '20px', lg: '24px' }, mb: { xs: 8, lg: 0 }, opacity: '.7', textAlign: { xs: 'center', lg: 'left' } }} >
                  Just configure your UPI and Spense will set up an account for you with partner bank. The money saved towards your goal will be deposited into partner bank escrow accounts.
                </Typography>
              </Grid>
              <Grid container lg={4} sx={{ justifyContent: { xs: 'center', lg: 'left' }, display: 'flex', alignItems: { xs: 'center', lg: 'flex-start' }, flexDirection: 'column' }}>
                <Box sx={{ height: 160 }} ><img src={HIW00} style={{ height: 140 }} /></Box>
                <Typography variant="h4" sx={{ textAlign: { xs: 'center', lg: 'left' } }} >
                  Spense is free, there are no hidden costs
                </Typography>
                <Typography variant="body1" sx={{ mt: { xs: '20px', lg: '24px' }, mb: { xs: 8, lg: 0 }, opacity: '.7', textAlign: { xs: 'center', lg: 'left' } }} >
                  It's completely free to save with Spense. But there’s a perk — you earn 10% cash bonus for saving on Spense.
                </Typography>
              </Grid>
              <Grid container lg={4} sx={{ justifyContent: { xs: 'center', lg: 'left' }, display: 'flex', alignItems: { xs: 'center', lg: 'flex-start' }, flexDirection: 'column' }}>
                <Box sx={{ height: 160 }} ><img src={HIW01} style={{ height: 140 }} /></Box>
                <Typography variant="h4" sx={{ textAlign: { xs: 'center', lg: 'left' } }} >
                  Withdraw anytime with instant transfer
                </Typography>
                <Typography variant="body1" sx={{ mt: { xs: '20px', lg: '24px' }, mb: { xs: 8, lg: 0 }, opacity: '.7', textAlign: { xs: 'center', lg: 'left' } }}>
                  You can withdraw your deposits anytime from your Spense app with instant transfer. No questions asked.
                </Typography>
              </Grid>
            </Grid>
          </Parallax>
        </Container>
      </Grid>
      {/* Saving is an everyday thing! Ends */}

    </div>
  );
};
export default HowItWorks;
