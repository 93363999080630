import React from "react";
import { Box, Grid } from "@mui/material";
import { Container } from '@mui/system';
import ResponsiveAppBar from "../components/responsiveAppBar";
import HeroBanner from "../components/heroBanner";
import HeroWings from "../components/heroWings";
import Features from "../components/features";
import HowItWorks from "../components/howItWorks";
import WhoWeAre from "../components/whoWeAre";
import OurTeam from "../components/ourTeam";
import InThePress from "../components/inThePress";
import FAQ from "../components/FAQ";
import Footer from "../components/footer";

import { Parallax } from 'react-scroll-parallax';
import { ParallaxProvider } from "react-scroll-parallax";
import OurPartners from "../components/ourPartners";
import OurAdvisors from "../components/ourAdvisors";


const Home = () => {
  return (
    <ParallaxProvider>
      <div>
        <ResponsiveAppBar />
        <HeroBanner />
        <Features />
        <HowItWorks />
        {/* <WhoWeAre /> */}
        <OurPartners />
        <OurTeam />
        <OurAdvisors/>
        
        {/* <InThePress /> */}
        <FAQ />
        <Footer/>
      </div>
    </ParallaxProvider>
  );
};
export default Home;
