import React from "react";
import { Box, Container, Grid, Typography, Link, Input, Button } from "@mui/material";
import Logo from "../assets/img/spense_logo.png";
import icoInsta from "../assets/img/ico-instagram.png";
import icoTwitter from "../assets/img/ico-twitter.png";
import icoFb from "../assets/img/ico-facebook.png";
import icoLinkedin from "../assets/img/ico-linkedin.png";
import "../App.css";

import { LinkedIn, Facebook, Twitter, Instagram } from '@mui/icons-material/';



import { Parallax } from 'react-scroll-parallax';

const Footer = () => {
  return (
    <Grid sx={{ backgroundColor: '#111' }}>
      <Container sx={{ display: { lg: 'flex', xs: 'inherit' } }}>
        <Grid container lg={6} xs={12} sx={{ p: { xs: '54px 0 0 0', lg: '72px 72px 72px 0' } }}>
          <Grid>
            <Grid><img src={Logo} style={{ width: '200px', height: '72px' }} /></Grid>
            <Grid sx={{ mt: { lg: 6, xs: 2 } }}>
              <Grid display={'flex'} gap={'24px'}>
                <Link component="button" variant="body2" style={{color:'#f1f1f1', textDecorationLine:'none'}}>Terms of service</Link>
                <Link component="button" variant="body2" style={{color:'#f1f1f1', textDecorationLine:'none'}}>Privacy policy</Link>
              </Grid>
              <Grid display={'flex'} gap={'24px'} sx={{ mt: { xs: 2 }, opacity: .7 }}>
                <Typography variant="body2">©2022 Spense Reliable Fintech Solutions Pvt. Ltd.</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container lg={5} xs={12} sx={{ justifyContent: { lg: 'right', xs: 'inherit' }, textAlign: { lg: 'right', xs: 'inherit' }, p: { xs: '32px 0 54px 0', lg: '72px 0px 72px 0' }, mt: { lg: 4 } }}>
          <Grid gap={'24px'}>
            <Typography variant="body2" sx={{ opacity: .7 }}>Spense Reliable Fintech Solutions Pvt. Ltd.</Typography>
            <Typography variant="body2" sx={{ opacity: .7 }}>Bengaluru 560076</Typography>
            <br/>
            {/* <Typography variant="body2" sx={{ opacity: .7 }}>(+91) 9986960637</Typography> */}
            <Typography variant="body2" ><a href="mailto:support@spense.money" target={'_blank'} style={{color:'#f1f1f1', textDecorationLine:'none'}} >support@spense.money</a></Typography>

          </Grid>
          <Grid display={'flex'} gap={'16px'} sx={{ mt: { xs: 2 } }}>
            <a href="https://instagram.com/spense.money?igshid=YmMyMTA2M2Y=" target={'_blank'}><Instagram fontSize="large" sx={{color:"#999"}}/></a>
            <a href="#" target={'_blank'}><Twitter fontSize="large" sx={{color:"#999"}}/></a>
            <a href="https://www.facebook.com/profile.php?id=100084296873328" target={'_blank'}><Facebook fontSize="large" sx={{color:"#999"}}/></a>
            <a href="https://www.linkedin.com/company/spense-money/" target={'_blank'}><LinkedIn fontSize="large" sx={{color:"#999"}}/></a>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Footer;
