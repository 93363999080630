import React from "react";
import { Box, Container, Grid, Typography, Input, Button, Divider } from "@mui/material";

const HeroUSP = () => {
  return (
    <div style={{ zIndex: '1000' }}>
      <Grid sx={{ display: { xs: 'block', lg: 'flex' }, mt: { xs: 0, lg: -5 }, height: '200px', alignSelf: 'center' }}>
        <Grid display={'flex'} >
          <Typography variant='h1' sx={{ fontSize: { xs: "120px", lg: "230px" }, letterSpacing: { xs: '-.2rem', lg: '-1rem' }, alignSelf: "center" }}>
            10
          </Typography>
          <Box style={{ flexDirection: "row", alignSelf: "center" }} sx={{ pl: { xs: "10px", lg: "40px" } }}>
            <Typography variant="h1" sx={{ fontSize: { xs: "80px", lg: "120px" }, textAlign: "center" }}>
              %
            </Typography>
            <Typography variant='h4' sx={{ lineHeight: { xs: "20px", lg: "60px" }, textAlign: "center", textTransform: "uppercase" }}>
              Bonus
            </Typography>
          </Box>
        </Grid>
        <Grid sx={{ ml: { xs: 0, lg: 10 }, mt: { xs: 4, lg: 0 }, alignSelf: 'center' }}>
          <Typography variant='h4'>
            Earn 10% cash bonus every time you save on Spense
          </Typography>
        </Grid>
      </Grid >

      <Divider sx={{ bgcolor: "white", mt: { xs: 5, lg: 4 }, opacity: '0.4' }} />

      <Grid container sx={{ mt: { xs: 2, lg: 8 }, justifyContent: "space-evenly" }}>

        <Grid lg={3} xs={12} sx={{ mt: { xs: 2, lg: 0 } }}>
          <Typography variant="h4" sx={{ fontSize: { xs: "40px", lg: "100px" }, alignSelf: "center", textAlign: { xs: "left", lg: "center" } }}>
            3X
          </Typography>
          <Typography variant="body1" sx={{ width: { xs: '90%', lg: '100%' }, alignSelf: "center", textAlign: { xs: "left", lg: "center" }, opacity: '.7' }}>
            "People who save in groups save three times more"<Typography variant="subtitle1"><i><a href='https://www.hbs.edu/ris/Publication%20Files/12-060_8c16f5e7-6fa1-48cc-858d-bba5f12c28ba.pdf' target={'_blank'} style={{ color: '#f1f1f1', textDecorationLine: 'none' }}>- Harvard Business School Research</a></i></Typography>
          </Typography>
        </Grid>

        <Divider orientation="vertical" sx={{ bgcolor: "white", display: { xs: 'none', lg: 'flex' }, opacity: '0.4' }} flexItem />

        <Grid lg={3} xs={12} sx={{ mt: { xs: 2, lg: 0 } }}>
          <Typography variant="h4" sx={{ fontSize: { xs: "40px", lg: "100px" }, alignSelf: "center", textAlign: { xs: "left", lg: "center" } }}>
            1,003
          </Typography>
          <Typography variant="body1" sx={{ width: { xs: '90%', lg: '100%' }, alignSelf: "center", textAlign: { xs: "left", lg: "center" }, opacity: '.7' }}>
            Over a thousand happy users are saving on Spense
          </Typography>
        </Grid>


        <Divider orientation="vertical" sx={{ bgcolor: "white", display: { xs: 'none', lg: 'flex' }, opacity: '0.4' }} flexItem />

        <Grid lg={3} xs={12} sx={{ mt: { xs: 2, lg: 0 } }}>
          <Typography variant="h4" sx={{ fontSize: { xs: "40px", lg: "100px" }, alignSelf: "center", textAlign: { xs: "left", lg: "center" } }}>
            ₹9.8L
          </Typography>
          <Typography variant="body1" sx={{ width: { xs: '90%', lg: '100%' }, alignSelf: "center", textAlign: { xs: "left", lg: "center" }, opacity: '.7' }}>
            Spense users have saved over nine lakh rupees
          </Typography>
        </Grid>

      </Grid>


    </div>
  );
};

export default HeroUSP;
