
import React from "react";
import { Box, Container, Grid, Typography, Input, Button, Divider } from "@mui/material";
import AD00 from "../assets/img/advisor00.png";
import AD01 from "../assets/img/advisor01.png";
import AD02 from "../assets/img/advisor02.png";
import AD03 from "../assets/img/advisor03.png";
import AD04 from "../assets/img/advisor04.png";
import AD05 from "../assets/img/advisor05.png";
import AD06 from "../assets/img/advisor06.png";

import Wing from "../assets/img/whoWeAreWing.png";
import { Parallax } from 'react-scroll-parallax';

const OurTeam = () => {
  return (
    <div>
      <Grid>
        <Parallax translateY={[10, -5]} >
          <Container>
            <Grid container>
              <Grid container lg={12} sx={{ p: { xs: '0px 0px 54px 0px', lg: '0px 0px 72px 0px' } }}>
                <Typography width='100%' variant='h4' sx={{ mb: { xs: '32px' } }}>Our advisors</Typography>
                <Grid display='flex' flexWrap={'wrap'} columnGap={2.5} rowGap={4} justifyContent={'flex-start'}>
                  <Box width={160}>
                    <a href='https://www.linkedin.com/in/akothari/' target={'_blank'} style={{ textDecoration: 'none' }}>
                      <img style={{ width: '80px', marginBottom: '12px' }} src={AD00} />
                      <Typography variant="body1">Akshay {<br />}Kothari</Typography>
                      <Typography variant="subtitle2" sx={{ opacity: '0.7', mt: 1 }}>COO, Notion</Typography>
                    </a>
                  </Box>
                  <Box width={160}>
                    <a href='https://www.linkedin.com/in/narahari-kadambi-20a9913b/' target={'_blank'} style={{ textDecoration: 'none' }}>
                      <img style={{ width: '80px', marginBottom: '12px' }} src={AD05} />
                      <Typography variant="body1" >Kadambi{<br />}Narahari</Typography>
                      <Typography variant="subtitle2" sx={{ opacity: '0.7', mt: 1 }}>Ex-CEO, SBI Cards</Typography>
                    </a>
                  </Box>
                  <Box width={160}>
                    <a href='https://www.linkedin.com/in/kunalshah1/' target={'_blank'} style={{ textDecoration: 'none' }}>
                      <img style={{ width: '80px', marginBottom: '12px' }} src={AD06} />
                      <Typography variant="body1" >Kunal{<br />}Shah</Typography>
                      <Typography variant="subtitle2" sx={{ opacity: '0.7', mt: 1 }}>CEO & Founder, CRED</Typography>
                    </a>
                  </Box>
                  <Box width={160}>
                    <a href='https://www.linkedin.com/in/ravisk/' target={'_blank'} style={{ textDecoration: 'none' }}>
                      <img style={{ width: '80px', marginBottom: '12px' }} src={AD02} />
                      <Typography variant="body1">Ravi{<br />}Sudhakar</Typography>
                      <Typography variant="subtitle2" sx={{ opacity: '0.7', mt: 1 }}>Director, Global Sales, Microsoft</Typography>
                    </a>
                  </Box>
                  <Box width={160}>
                    <a href='https://www.linkedin.com/in/sayandeb-banerjee-617a652/?originalSubdomain=in' target={'_blank'} style={{ textDecoration: 'none' }}>
                      <img style={{ width: '80px', marginBottom: '12px' }} src={AD03} />
                      <Typography variant="body1" >Sayandeb{<br />}Banerjee</Typography>
                      <Typography variant="subtitle2" sx={{ opacity: '0.7', mt: 1 }}>Co founder & CEO, The Math Company</Typography>
                    </a>
                  </Box>
                  <Box width={160}>
                    <a href='https://www.linkedin.com/in/sureshrv/' target={'_blank'} style={{ textDecoration: 'none' }}>
                      <img style={{ width: '80px', marginBottom: '12px' }} src={AD04} />
                      <Typography variant="body1" >Suresh{<br />}Rayasam</Typography>
                      <Typography variant="subtitle2" sx={{ opacity: '0.7', mt: 1 }}>Head of Engineering, GTM, LinkedIn</Typography>
                    </a>
                  </Box>
                  {/* <Box width={160}>
                    <a href='https://www.linkedin.com/in/angshuman1975/' target={'_blank'} style={{ textDecoration: 'none' }}>
                      <img style={{ width: '80px', marginBottom: '12px' }} src={AD01} />
                      <Typography variant="body1">Angshuman{<br />}Chatterjee</Typography>
                      <Typography variant="subtitle2" sx={{ opacity: '0.7', mt: 1 }}>Senior VP II, HDFC</Typography>
                    </a>
                  </Box> */}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Parallax>
      </Grid>

    </div>
  );
};
export default OurTeam;