/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import LogoBig from "../assets/img/spense_logo.png";
import ScrollToColor from "./scrollToColor";
import { Grid } from "@mui/material";
import { Fonts } from "../assets/font";
import { Container } from "@mui/system";
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const pages = ["How it works?", "Features", "Our team", "Contact us"];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const scrollPagetoPixelDSKT = (selPage) => {
    switch (selPage) {
      case pages[0]:
        scroll.scrollTo(1460)
        break;
      case pages[1]:
        scroll.scrollTo(2050)
        break;
      case pages[2]:
        scroll.scrollTo(6550)
        break;
      case pages[3]:
        scroll.scrollTo(10000)
        break;
      case ('Home'):
        scroll.scrollTo(0)
        break;
      default:
        break;
    }
  }

  const scrollPagetoPixelMOBL = (selPage) => {
    switch (selPage) {
      case pages[0]:
        scroll.scrollTo(1750)
        break;
      case pages[1]:
        scroll.scrollTo(2150)
        break;
      case pages[2]:
        scroll.scrollTo(6810)
        break;
      case pages[3]:
        scroll.scrollTo(10000)
        break;
      case ('Home'):
        scroll.scrollTo(0)
        break;
      default:
        break;
    }
  }

  return (
    <ScrollToColor>
      <AppBar position="sticky">
        <Container >
          {/* Mobile Header Starts */}
          <Grid sx={{ display: { xs: "flex", lg: "none", flexGrow: 1, justifyContent: "space-between", alignItems: "center" } }}>
            <Grid>
              <IconButton>
                <img src={LogoBig} width="220px" height="80px" id='LogoMobile' onClick={() => { scrollPagetoPixelDSKT('Home') }}/>
              </IconButton>
            </Grid>
            <Grid marginTop={2}>
              <IconButton aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
                <MenuIcon size="large" />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left"
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" }
                }}
              >
                {pages.map(page =>
                  <MenuItem key={page} onClick={() => { handleCloseNavMenu(); scrollPagetoPixelMOBL(page); }}>
                    <Typography variant='h4' textAlign="center" style={{ color: '#111' }}>
                      {page}
                    </Typography>
                  </MenuItem>
                )}
              </Menu>
            </Grid>
          </Grid>
          {/* Mobile Header Ends */}

          {/* Desktop Header Starts */}
          <Grid container sx={{ display: { xs: "none", lg: "flex" }, justifyContent:'space-between' }} >
            <Grid item lg={4}>
              <IconButton >
                <img src={LogoBig} width="352px" height="120px" id='LogoDesktop' onClick={() => { scrollPagetoPixelDSKT('Home') }} />
              </IconButton>
            </Grid>
            <Grid item lg={8} marginTop={9} paddingLeft={6} id='MenuDesktop' sx={{display:'flex', justifyContent:'flex-end'}} >
              {pages.map(page =>
                <Button style={{fontFamily:'Inter'}} key={page} onClick={() => { handleCloseNavMenu(); scrollPagetoPixelDSKT(page); }} sx={{ marginLeft: '24px' }}>
                  <Typography variant="body2">
                    {page}
                  </Typography>
                </Button>
              )}
            </Grid>
            {/* <Grid item lg={2} align="right" marginTop={7.5} id='CTADestkop'>
              <Button variant="outlined" size="large" style={{ color: "#F9F9F9", borderColor: "#F9F9F9", borderRadius: "50px", textTransform: "none", height: "60px", width: "180px" }}>
                <Typography variant="body2">
                  Join the waitlist
                </Typography>
              </Button>
            </Grid> */}
          </Grid>
          {/* Desktop Header Ends */}
        </Container>
      </AppBar>
    </ScrollToColor>
  );
};
export default ResponsiveAppBar;
