
import React from "react";
import { Box, Container, Grid, Typography, Input, Button, Divider } from "@mui/material";
import TP00 from "../assets/img/teamMember00.png";
import TP01 from "../assets/img/teamMember01.png";
import TP02 from "../assets/img/teamMember02.png";
import TP03 from "../assets/img/teamMember03.png";

import Wing from "../assets/img/whoWeAreWing.png";
import { Parallax } from 'react-scroll-parallax';

const OurTeam = () => {
  return (
    <div>
      <Grid>
        <Container>
          <Grid container>
            <Grid container lg={8} sx={{ p: { xs: '54px 0 54px 0', lg: '72px 0 72px 0' } }}>

              <Typography width='100%' variant='h3' sx={{ mb: { xs: '32px', lg: '0px' } }}>Meet our team</Typography>

              <Grid display='flex' flexWrap={'wrap'} columnGap={2.5} rowGap={4} justifyContent={'flex-start'}>
                <Box width={160}>
                  <a href='https://www.linkedin.com/in/pawankumar91/' target={'_blank'} style={{ textDecoration: 'none' }}>
                    <img style={{ width: '80px', marginBottom: '12px' }} src={TP00} />
                    <Typography variant="body1">Pawan{<br />}Kumar</Typography>
                    <Typography variant="subtitle2" sx={{ opacity: '0.7', mt: 1 }}>CEO & Co Founder</Typography>
                  </a>
                </Box>
                <Box width={160}>
                  <a href='https://www.linkedin.com/in/srinivas-krishnamurthy-5ba8a77/' target={'_blank'} style={{ textDecoration: 'none' }}>
                    <img style={{ width: '80px', marginBottom: '12px' }} src={TP02} />
                    <Typography variant="body1">Srinivas{<br />}Krishnamurthy</Typography>
                    <Typography variant="subtitle2" sx={{ opacity: '0.7', mt: 1 }}>CTO & Co Founder</Typography>
                  </a>
                </Box>
                <Box width={160}>
                  <a href='https://www.linkedin.com/in/iqbalmohammeda/' target={'_blank'} style={{ textDecoration: 'none' }}>
                    <img style={{ width: '80px', marginBottom: '12px' }} src={TP01} />
                    <Typography variant="body1">Mohammed{<br />}Iqbal</Typography>
                    <Typography variant="subtitle2" sx={{ opacity: '0.7', mt: 1 }}>Head of Design & <br />Co Founder</Typography>
                  </a>
                </Box>
                <Box width={160}>
                  <a href='https://www.linkedin.com/in/atvinaykumar/' target={'_blank'} style={{ textDecoration: 'none' }}>
                    <img style={{ width: '80px', marginBottom: '12px' }} src={TP03} />
                    <Typography variant="body1" >Vinay{<br />}Kumar</Typography>
                    <Typography variant="subtitle2" sx={{ opacity: '0.7', mt: 1 }}>Head of Business Operations & Strategy</Typography>
                  </a>
                </Box>
              </Grid>
            </Grid>

            <Grid container lg={4} sx={{ p: { xs: '0px 0 54px 0', lg: '72px 0px 72px 0px' }, mt: { xs: '', lg: '64px' } }}>

              <Typography width='100%' variant="h4" sx={{ mt: { xs: '24px', lg: '32px' }, background: 'linear-gradient(91.5deg, #FFF500 1%, #FF1C7B 98.31%)', WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }}>We are hiring!</Typography>
              <Typography width='100%' variant="body1" sx={{ mt: { xs: '10px', lg: '16px' }, opacity: '.7' }}  >
                We're looking for people with passion to join our team!
              </Typography>
              <Button variant="outlined" size="large" sx={{ mt: 2 }} style={{ color: "#F9F9F9", borderColor: "#F9F9F9", borderRadius: "50px", textTransform: "none", height: "60px", width: "180px" }} href='https://spensemoney.notion.site/Job-Board-f5e28e4d63064ca8869322cdeef96fac' target={'_blank'}>
  
                <Typography variant="body2">
                  Join our team
                </Typography>
  
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </div>
  );
};
export default OurTeam;