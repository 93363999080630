import React from "react";
import { Box, Container, Grid, Typography, Input, Button, Divider } from "@mui/material";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: "transparent",
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  padding: 0,
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .0)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const PressNFAQ = () => {
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div>
      {/* Saving is an everyday thing! Starts */}

      <Grid backgroundColor='#EBE0FF' >
        <Container>
          <Grid container>
            <Grid container sx={{ p: { xs: '54px 0 54px 0', lg: '72px 0 72px 0' } }}>

              <Typography width='100%' height={30} variant='h3' sx={{ mb: { xs: '32px', lg: '48px' } }} style={{ color: '#111' }}>FAQs</Typography>

              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography variant="h5" style={{ color: '#360094' }}>Is Spense a bank?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" style={{ color: '#111', opacity: ".7" }}>
                    Spense is safe as a bank and partners with RBI regulated bank to keep your money safe. Your deposits are saved on Yes Bank’s trustee escrow account.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                  <Typography variant="h5" style={{ color: '#360094' }}>How Spense works?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" style={{ color: '#111', opacity: ".7" }}>
                    Spense app allows users to create savings goals and transfer money into them, in an insured bank account. Target completion dates, invite friends or contributors, and automated deposits all help users make steady progress towards their goals. An in-app content and social experiences provides inspiration, tactics, and tips. Prompts to take mindful moments further encourage users to develop greater calm, awareness, and control of their money. We are improving upon the age-old banking system without compromising on security & safety, allowing you, our users, to plan efficiently.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                  <Typography variant="h5" style={{ color: '#360094' }}>How is Spense able to offer up to 20% rewards on my plans?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" style={{ color: '#111', opacity: ".7" }}>
                    Your Money Earns Interest + Brand Rewards<br />
                    Your money is parked at our partner banks that earn a cash bonus. Through our intelligent investment framework, we pump in your savings into different instruments based on your goal type to earn you the maximum cash interest without compromising on the principal amount. Furthermore, we have partnered with various trusted brands to bring you the best deals. These brands reward you for the planning and dedication you show towards them.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              {/* <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                  <Typography variant="h5" style={{ color: '#360094' }}>Will I have to declare the income from Spense somewhere?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" style={{ color: '#111', opacity: ".7" }}>
                    Capital gain tax is applicable for cash bonus earned on Spense. But the brand rewards are completely tax-free and you do not have to worry about declaring them anywhere.                 </Typography>
                </AccordionDetails>
              </Accordion> */}
              <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                  <Typography variant="h5" style={{ color: '#360094' }}>Can I withdraw my money at anytime?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" style={{ color: '#111', opacity: ".7" }}>
                    You can withdraw your money any time — no questions asked. But you will forfeit the incentives earned and you will only get your original money back.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                  <Typography variant="h5" style={{ color: '#360094' }}>What are the fees and charges of the programme?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" style={{ color: '#111', opacity: ".7" }}>
                    Absolutely nothing. No joining fee, no annual fee, no other fee.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                  <Typography variant="h5" style={{ color: '#360094' }}>Which banks do you keep the money with?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" style={{ color: '#111', opacity: ".7" }}>
                    Your money is safely kept at either Yes Bank, Federal or HDFC Bank trustee escrow accounts. No one, except you or your group, can touch your savings.               </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                  <Typography variant="h5" style={{ color: '#360094' }}>How do I ensure that my money is safe with Spense?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" style={{ color: '#111', opacity: ".7" }}>
                    We've built this app so that our friends and families can also enjoy a better savings experience, and we take the security of your money very seriously. All your money is stored at RBI-regulated banks from the moment you transfer them as part of one of your goals.           </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">
                  <Typography variant="h5" style={{ color: '#360094' }}>Who can withdraw the money for group saving goals?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" style={{ color: '#111', opacity: ".7" }}>
                    Everyone saving together will be able to withdraw the entire savings or partial savings into their primary account after taking consent from the other contributors.
                    <br /><br />However, group withdrawals are only supported after the completion of the goal. You are only allowed to withdraw your contribution if you intend to withdraw the money before goal completion.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                <AccordionSummary aria-controls="panel10d-content" id="panel10d-header">
                  <Typography variant="h5" style={{ color: '#360094' }}>Are there any taxes on earnings made through Spense?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" style={{ color: '#111', opacity: ".7" }}>
                    Like any other investment, capital gain tax is applicable on cash bonuses earned with Spense.</Typography>
                </AccordionDetails>
              </Accordion>

            </Grid>
          </Grid>
        </Container>

      </Grid>

    </div>
  );
};
export default PressNFAQ;