import React from "react";
import { useScrollTrigger } from "@material-ui/core";

const ScrollHandler = props => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: props.window ? window() : undefined,
  });

  return React.cloneElement(props.children, {
    style: {
      background: trigger ? "rgba(33, 0, 103, .8)" : "transparent",
      backdropFilter:trigger? 'blur(30px)': 'none',
      color: trigger ? "white" : "white",
      transition: trigger ? "0.2s" : "0.3s",
      boxShadow: "none",
    },
  });
};

const ScrollToColor = props => {
  return (
    <ScrollHandler {...props}>
      {props.children}
    </ScrollHandler>
  );
};

export default ScrollToColor;
